// ##################################################
// Button
// ##################################################

@import '../../../scss/import';

// Style
.btn {
	cursor: pointer;
	border-radius: 0;
	padding: 4px 12px;
	min-width: 208px;
	min-height: 40px;
	background-color: transparent;
	color: var(--color-white);
	display: inline-flex;
	align-items: center;
	border: 0;
	font-size: 14px;
	justify-content: center;
	line-height: 1;
	font-family: inherit;
	text-transform: uppercase;
	position: relative;

	// ##################################################
	// Background

	&::before {
		@extend %content;
		background-color: var(--color-red);
		transform: skew(-20deg, 0);
	}

	// ##################################################

	// Button text
	.btn__text {
		display: block;
		position: relative;
		z-index: 3;
	}
}
