// **************************************************
// Radio Button
// **************************************************

@import '../../../scss/import';

.radio-button {
	display: flex;
	align-items: center;
	user-select: none;

	// ##################################################

	&__label {
		display: inline-block;
		position: relative;
		padding-left: 30px;
		line-height: normal;
		cursor: pointer;
		margin: 0;
		transition: color 0.3s ease;

		// ##################################################

		&::before,
		&::after {
			content: '';
			position: absolute;
			border-radius: 100%;
		}

		// ##################################################

		// Unchecked circle
		&::before {
			width: 20px;
			height: 20px;
			background-color: var(--color-white);
			border: 0;
			left: 0;
			top: 0;
			transition: box-shadow 0.3s ease;
		}

		// ##################################################

		// Checked circle
		&::after {
			left: 5px;
			top: 5px;
			width: 10px;
			height: 10px;
			background-color: var(--color-blue);
			transition: transform 0.3s ease;
			transform: scale(0);
		}
	}

	// ##################################################

	a {
		border: 0;
		text-decoration: underline;
	}

	// ##################################################

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:checked + .radio-button__label {
			&::after {
				@include scale(1);
			}
		}

		// ##################################################

		&:focus + .radio-button__label {
			&::before {
				box-shadow: 0 0 0 3px rgba(#00324e, 0.2);
			}
		}
	}
}
