//**********************************************************
// Intro screen
//**********************************************************

@import '../../../scss/import';

.intro-screen {
	position: relative;
	padding: 60px 0 50px;

	// ##################################################

	&__title {
		text-align: center;
		max-width: 270px;
		margin-bottom: 28px;
	}

	// ##################################################

	&__form {
		margin-top: 36px;
	}

	// ##################################################

	&__button {
		margin-top: 30px;
	}

	// ##################################################

	&__info-text {
		margin-top: 36px;
	}

	// ##################################################

	&__logo {
		text-align: center;
		margin-bottom: 40px;

		// ##################################################

		&-inner {
			max-width: 260px;
			display: inline-block;
		}
	}

	// ##################################################

	.custom-dropdown {
		margin: 42px 0 28px;
	}

	// ##################################################

	&--data-entry {
		.intro-screen__logo-inner {
			max-width: 150px;
		}
	}
}
