// **************************************************
// Checkbox
// **************************************************

@import '../../../scss/import';

.checkbox {
	display: flex;
	align-items: center;
	min-height: 30px;
	user-select: none;

	// ##################################################

	& + .checkbox {
		margin-top: 24px;
	}

	// ##################################################

	&__label {
		display: inline-block;
		line-height: normal;
		position: relative;
		padding-left: 34px;
		cursor: pointer;
		margin: 0;
		transition: color 0.3s ease;

		// ##################################################

		&::before,
		&::after {
			content: '';
			position: absolute;
			border-radius: 100%;
		}

		// ##################################################

		// Unchecked circle
		&::before {
			width: 20px;
			height: 20px;
			background-color: var(--color-white);
			border: 0;
			left: 0;
			top: 0;
			transition: box-shadow 0.3s ease;
		}

		// ##################################################

		// Checked circle
		&::after {
			left: 5px;
			top: 5px;
			width: 10px;
			height: 10px;
			background-color: var(--color-blue);
			transition: transform 0.3s ease;
			transform: scale(0);
		}
	}

	// ##################################################

	a {
		border: 0;
		text-decoration: underline;
	}

	// ##################################################

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		// ##################################################

		&:checked + .checkbox__label {
			&::after {
				@include scale(1);
			}
		}

		// ##################################################

		&:focus + .checkbox__label {
			&::before {
				box-shadow: 0 0 0 3px rgba(#00324e, 0.2);
			}
		}
	}

	// ##################################################

	&.survey-checkbox,
	&.agree-checkbox {
		font-size: 13px;
		font-weight: 400;
		line-height: 1.3;

		.checkbox__label {
			line-height: inherit;
		}
	}

	&.survey-checkbox {
		.checkbox__label {
			opacity: 0.7;
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		color: var(--color-red);
	}
}
