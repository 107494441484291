// **************************************************
// Personal Data
// **************************************************

@import '../../../scss/import';

.personal-data {
	width: 100%;
	margin: 34px 0;

	// ##################################################

	.input-field {
		margin-bottom: 16px;

		// ##################################################

		&__label {
			margin-bottom: 5px;
		}
	}

	// ##################################################

	&__info-text {
		margin-top: 20px;
		line-height: normal;
	}
}
