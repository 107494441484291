//**********************************************************
// INPUT FIELD
//**********************************************************

@import '../../../scss/import';

.input-field {
	width: 100%;
	position: relative;
	text-align: left;
	margin-bottom: 30px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 20px;
	}

	// ##################################################

	&__inner {
		position: relative;
	}

	// ##################################################

	&__label {
		margin-bottom: 2px;
	}

	// ##################################################

	&__input {
		width: 100%;
		height: 35px;
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
		background-color: var(--color-white);
		border: 1px solid transparent;
		padding: 0 15px;
		border-radius: 0;
		transition: border 0.3s ease;

		// stylelint-disable-next-line
		.#{$class_validation_failed} & {
			color: var(--color-validation);
		}

		&:focus {
			border-color: var(--color-font);
		}
	}

	// ##################################################

	textarea {
		resize: none;
	}

	// ##################################################

	&.is-disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}
