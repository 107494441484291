//**********************************************************
// Thankyou screen
//**********************************************************

@import '../../../scss/import';

.thankyou-screen {
	position: relative;
	padding: 32px 0 0;

	// ##################################################

	&__sub-title {
		text-align: center;
		margin-bottom: 38px;
	}

	// ##################################################

	.title {
		margin-bottom: 24px;
	}

	// ##################################################

	&__logo {
		text-align: center;
		margin-bottom: 32px;

		// ##################################################

		&-inner {
			max-width: 150px;
			display: inline-block;
		}
	}
}
