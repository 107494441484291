// **************************************************
// Footer
// **************************************************

@import '../../../scss/import';

.site-footer {
	width: 100%;
	padding: 25px 0;
	background-color: var(--color-gray);
	color: var(--color-white);

	// ##################################################

	&__nav {
		line-height: 1.71;

		// ##################################################

		ul {
			display: grid;
			grid-gap: 0;
		}

		// ##################################################

		a {
			display: inline-block;
			position: relative;
			@extend %hover_lines;
		}
	}
}
