// **************************************************
// Radiobox Inline
// **************************************************

@import '../../../scss/import';

.radiobox-inline {
	margin: 30px 0 22px;

	// ##################################################

	.title {
		margin-bottom: 20px;
	}

	// ##################################################

	.radio-button {
		display: inline-block;

		// ##################################################

		&:not(:last-child) {
			margin-right: 28px;
		}
	}

	// ##################################################

	&__info-text {
		margin-top: 14px;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		// stylelint-disable-next-line
		.radio-button__label {
			color: var(--color-red);
		}

		// stylelint-disable-next-line
		.birth-date__validation-message {
			display: block;
		}

		// stylelint-disable-next-line
		.input-field__input {
			color: var(--color-red);
			border-color: var(--color-red);
		}
	}
}
