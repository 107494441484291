// **************************************************
// Button Group
// **************************************************

@import '../../../scss/import';

.button-group {
	display: grid;
	grid-gap: 14px;
	justify-items: flex-start;

	.btn {
		min-width: 256px;
		padding: 4px 20px;
	}
}
