// ##################################################
// Colors
// ##################################################

:root {
	--color-white:			#{$cgp_white};
	--color-black:			#{$cgp_black};
	--color-font:			#{$cgp_font};
	--color-blue:			#{$cgp_blue};
	--color-red:			#{$cgp_red};
	--color-gray:			#{$cgp_gray};
	--color-gray-dark:		#{$cgp_gray_dark};
	--color-validation:		#{$cgp_red};
	--space-16px:			16px;
	--space-24px:			24px;
	--space-40px:			40px;
	--space-60px:			60px;
	--space-80px:			80px;
	--space-100px:			100px;
	--space-120px:			120px;
}
