// **************************************************
// Reward Card
// **************************************************

@import '../../../scss/import';

.reward-card {
	width: 100%;
	max-width: 322px;
	margin: 0 auto;
	padding: 10px 0;
	font-weight: 700;
	display: grid;
	line-height: 1.3;
	position: relative;
	grid-template-columns: 284px 1fr;
	margin-bottom: 26px;

	// ##################################################

	&__left {
		left: -32px;
		position: relative;
	}

	// ##################################################

	&__right {
		margin-left: -56px;
		padding: 8px 0;
	}

	// ##################################################

	&__image {
		max-width: 275px;
		position: relative;
		margin-top: -20px;

		// ##################################################

		img {
			position: relative;
			z-index: 3;
		}
	}

	// ##################################################

	&__content {
		text-align: center;
		margin-top: 10px;

		.reward-card__content {
			margin-top: 2px;
		}
	}

	// ##################################################

	&__points {
		color: var(--color-blue);
		margin-bottom: 2px;
	}

	// ##################################################

	&__gift-card {
		position: relative;

		// ##################################################

		img {
			max-width: 102px;
			position: relative;
			z-index: 3;
		}

		// ##################################################
		// Background

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			width: 84px;
			height: 84px;
			background-color: #db0a16;
			transform: translate(-50%, -50%);
			border-radius: 100%;
		}
	}

	// ##################################################

	&__hand {
		position: absolute;
		left: 55%;
		text-align: center;
		font-size: 11px;
		font-weight: 500;
		top: -8px;
	}

	// ##################################################

	&__info {
		display: block;
		margin: 0 auto;
		font-size: 11px;
		font-weight: 300;
		max-width: 100px;
		color: var(--color-white);
		padding-top: 20px;
		line-height: 1;

		// ##################################################
		// Background

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: -6px;
			width: 176px;
			height: 176px;
			// height: 254px;
			background-color: #db0a16;
			transform: translate(-50%, 0);
			border-radius: 90px;
			transition: height 0.3s ease;
		}

		// ##################################################

		&-inner {
			min-height: 1px;
			width: 100%;
			display: none;
		}

		// ##################################################

		&-item {
			display: grid;
			align-items: center;
			grid-template-columns: 22px 1fr;
			grid-gap: 12px;
			position: relative;
			z-index: 3;
			margin-bottom: 8px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		// ##################################################

		&-icon {
			text-align: center;

			img {
				display: inline-block;
			}
		}
	}

	// ##################################################

	&__button {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 9;
		cursor: pointer;
		font-size: 0;
		line-height: 0;
		opacity: 0;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_active} {
		// stylelint-disable-next-line
		.reward-card__info::before {
			height: 252px;
		}
	}
}
