// ##################################################
// BACKGROUNDS
// ##################################################

.cover-bg {
	@extend %abs_pos_fullwidth;
	background-size: cover;
	background-position: center center;

	img {
		display: none;
	}
}

.covered {
	@extend %abs_pos_fullwidth;
	max-height: initial;
}

// ##################################################
// TEXT ALIGN
// ##################################################

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

// ##################################################
// SCREEN READER
// ##################################################

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.button {
	@extend %reset_button;
}

// ##################################################
// TEXT
// ##################################################

.text {
	// ##################################################

	&--30 {
		@extend %fontsize30;
	}

	// ##################################################

	&--22 {
		@extend %fontsize22;
	}

	// ##################################################

	&--18 {
		@extend %fontsize18;
	}

	// ##################################################

	&--16 {
		@extend %fontsize16;
	}

	// ##################################################

	&--14 {
		@extend %fontsize14;
	}

	// ##################################################

	&--13 {
		@extend %fontsize13;
	}

	// ##################################################

	&--12 {
		@extend %fontsize12;
	}

	// ##################################################

	&--white {
		color: var(--color-white);
	}

	// ##################################################

	&--bold {
		font-weight: 700;
	}

	// ##################################################

	&--md {
		font-weight: 500;
	}

	// ##################################################

	&--uppercase {
		text-transform: uppercase;
	}
}

// ##################################################
// VISIBILITY
// ##################################################

.hide {
	display: none !important;
}

.show {
	display: block !important;
}

.d-flex {
	display: flex;
}

.d-flex-cc,
.d-flex-center-center {
	@extend %flex_center_center;
}

.container {
	width: 100%;
	margin: 0 auto;
	max-width: calc(100% - 70px);

	// Tablet P and up
	@media #{$breakpoint-sm-up} {
		max-width: 600px;
	}

	// ##################################################

	.container {
		max-width: 100%;
	}
}

.mx-auto {
	display: block;
	width: 100%;
	margin: 0 auto;
}
