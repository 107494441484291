// **************************************************
// Code Input
// **************************************************

@import '../../../scss/import';

.code-input {
	width: 100%;
	position: relative;

	// ##################################################

	&__row {
		grid-gap: 10px;
		grid-template-columns: repeat(6, minmax(0, 1fr));
		display: none;

		// stylelint-disable-next-line
		&.#{$class_active} {
			display: grid;
		}
	}

	// ##################################################

	&__inner {
		display: grid;
		grid-gap: 20px;
	}

	// ##################################################

	.input-field {
		min-width: 1px;
		width: 100%;
		margin: 0;

		// ##################################################

		&__input {
			width: 100%;
			padding: 0;
			text-align: center;
			height: 64px;
			box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
			color: var(--color-gray-dark);
			font-size: 28px;
		}
	}

	// ##################################################

	&__footer {
		display: grid;
		margin-top: 16px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		font-size: 14px;
		line-height: 1.1;
	}

	// ##################################################

	&__button {
		text-align: left;
		display: flex;
		color: var(--color-gray-dark);

		// ##################################################

		&.hide {
			display: none;
		}

		// ##################################################

		&-icon {
			font-size: 23px;
			line-height: 0.8;
		}

		// ##################################################

		&-text {
			padding-left: 8px;
		}
	}

	// ##################################################

	&__validation-message {
		display: none;
		color: var(--color-red);
		text-align: right;
	}
}
