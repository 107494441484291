// ##################################################
// Grids
// ##################################################

$css_grid: false !default; // Use CSS Grid (true) or Flexbox (false)

// ##################################################
// Main Fonts
// ##################################################

$font_primary:		'Gotham', sans-serif;

// ##################################################
// Colors
// ##################################################

// NEUTRAL COLORS
$cgp_white:					#fff;
$cgp_black:					#000;
$cgp_font:					#02122f;
$cgp_blue:					#063a8a;
$cgp_red:					#ff0000;
$cgp_gray:					#bababa;
$cgp_gray_dark:				#747272;
$cgp_validation_failed:		#ff0000;

// ##################################################
// Z-index
// ##################################################

$zindex_modal:			999;
$zindex_cookie_notice:	998;
$zindex_site_loader:	101;
$zindex_header:			100;
$zindex_menu_more:		101;
$zindex_slider_arrows:	89;

// ##################################################
// BOX SHADOW
// ##################################################

$vh_100:				calc(var(--vh, 1vh) * 100);

// ##################################################
// Class names (read from config JSON)
// ##################################################

// Usage: body.#{$class_open_menu} {};

$class_active:				"is-active";
$class_open:				"is-open";
$class_open_menu:			"is-open-menu";
$class_open_modal:			"is-open-modal";
$class_open_submenu:		"is-open-submenu";
$class_open_search:			"is-search-menu";
$class_validation_failed:	"is-validation-failed";
$class_visible:				"is-visible";
$class_hidden:				"is-hidden";
$class_focus:				"is-focused";
$class_disabled:			"is-disabled";
$class_failed:				"is-failed";
$class_loading:				"is-loading";
$class_category_open:		"is-open-category";
$class_selected:			"is-selected";
$class_active_slide:		"swiper-slide-active";
$class_active_thumbslide:	"swiper-slide-thumb-active";


// ##################################################
// Transitions & easings
// ##################################################

// Easings
$easing1:		cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easingType1:	cubic-bezier(0.46, 0.03, 0.52, 0.96);
$easingType2:	cubic-bezier(0.19, 1, 0.22, 1);

// Transitions
$transition_duration:	250ms;
$transition_easing:		linear;

$transition_easing_fadeup:			cubic-bezier(0.46, 0.03, 0.52, 0.96);
$transition_easing_fadeLeftRight:	cubic-bezier(0.19, 1, 0.22, 1);
$transition_easeInOutCubic:			cubic-bezier(0.645, 0.045, 0.355, 1);

// ##################################################
// Paths
// ##################################################
$image_path:				"../images";
